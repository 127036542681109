<template>
    <div v-if="isSelected" class="itemselect">
        <slot>
            
        </slot>
    </div>
    
</template>

<script>

export default {
    props: {
        isSelected: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
</style>
