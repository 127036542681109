import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_CARRO_URL, VUE_APP_SPONSOR } = process.env;

export default class ShieldService {
  static async createStep(values, step, version, id) {
    try {
      var carroRes;
      var data = {};
      var lenght = 0;

      //Para el primer step del formulario
      if (step == 1) {
        //Para el primera interacción
        if (version == 1) {
          data = {
            name: values[0],
            email: values[1],
            phoneNumber: values[2],
          };
          const params = {
            sponsor: VUE_APP_SPONSOR,
            stepOne: data,
            lenght: 1,
          };

          carroRes = await axios.post(
            `${VUE_APP_CARRO_URL}/step/create`,
            params
          );
        }
        //Para las demás interacciones interacción
        else {
          values.forEach((element) => {
            if (element != null) {
              lenght = lenght + 1;
            }
          });
          data = {
            name: values[0],
            email: values[1],
            phoneNumber: values[2],
          };
          const params = {
            sponsor: VUE_APP_SPONSOR,
            stepOne: data,
            id: id,
            lenght: lenght,
          };

          carroRes = await axios.put(
            `${VUE_APP_CARRO_URL}/step/update`,
            params
          );
        }
      }
      //Para el segundo step del formulario
      else if (step == 2) {
        const params = {
          sponsor: VUE_APP_SPONSOR,
          steptwo: values,
          id: id,
          lenght: 4,
        };

        carroRes = await axios.put(`${VUE_APP_CARRO_URL}/step/update`, params);
      }
      //Para el tercer step del formulario
      else if (step == 3) {
        values.forEach((element) => {
          if (element != null) {
            lenght = lenght + 1;
          }
        });
        lenght = lenght + 1;
        data = {
          name: values[0],
          surname: values[1],
          serialNumber: values[2],
          rut: values[3],
          region: values[4],
          commune: values[5],
          address: values[6],
          email: values[7],
          phoneNumber: values[8],
          species: values[9],
          breed: values[10],
          petName: values[11],
          petAge: values[12],
          sex: values[13],
          hasMicrochip: values[14],
        };
        const params = {
          sponsor: VUE_APP_SPONSOR,
          stepthree: data,
          id: id,
          lenght: lenght,
        };

        carroRes = await axios.put(`${VUE_APP_CARRO_URL}/step/update`, params);
      }

      return carroRes;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al crear el Step",
      };
    }
  }

  //Lógica para redireccionar los planes cuando tiene un código de descuento
 static async getPlans(validity, code) {
   //var tachado = priceCLP; 
    try {
      var url = `${VUE_APP_SHIELD_URL}/sport`; // URL inicial apuntando a backend gallagher
      var params=""; // URL con parametro de código de descuento
     
      if(code){ //If URL con código
        params = url+`?sponsor=${VUE_APP_SPONSOR}&code=${code}&pack=${validity}`;
        //priceCLP = "<strike>{{ formatPrice(planData.priceCLP) }}</strike>";
      }else{ // else URL sin código
        params = url+`?sponsor=${VUE_APP_SPONSOR}&pack=${validity}`;
      }
           
      const shieldRes = await axios.get(
        //Entrega URL con parametros
        params        
      );
      
     return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
      } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  // static async createInsurance(person, plan, stepId) {
  static async createInsurance(person, plan) {
    
    // var BenefData = []

    // person.BenefData.forEach(beneficiario => {
    //   if(beneficiario.name != null){
    //     BenefData.push(beneficiario)
    //   }
    // });

    // person.BenefData = BenefData

    person.region = person.region.id;
    person.commune = person.commune.id;
    // const jsonData = { person, planId: plan._id, stepId: stepId };
    const jsonData = { person, planId: plan._id};
    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/sport`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }
}
