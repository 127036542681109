<template>
  <v-container>

    <v-alert v-if="success === true" dense text type="success">
      ¡Has contratado tu seguro con éxito! Revisa tu <strong>correo electrónico</strong> para obtener toda la
      información.
    </v-alert>
    <v-alert v-if="success === false" dense text type="error">
      Ha ocurrido un error al contratar tu seguro. Intenta nuevamente.
    </v-alert>

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header style="box-shadow: none">
        <v-stepper-step :complete="e1 > 1" step="1"> Inicio </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> Planes </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> Tus datos </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <div v-if="loading" class="text-center">
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
            Cargando
          </div>
          <InitForm v-else :userData="userData" @next-step="nextStep(1)" />

          <!-- <v-btn color="primary" @click="nextStep(1)"> Siguiente </v-btn> -->

          <!-- <v-btn text> Cancel </v-btn> -->
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-container style="justify-content: center; display: flex">
            <v-col>
              <Conditions />
              <div class="plan-container">
                <OpenPlan :plan_1="plan_1" :plan_3="plan_3" :plan_6="plan_6" :plan_12="plan_12" @next-step="nextStep" />
                <!-- <Plan
                    v-for="plan in plans"
                    :key="plan.id"
                    :planData="plan"
                     @next-step="nextStep"
                     @back="back(2)" 
              /> -->
              </div>
              <v-row style="justify-content: center; margin-top: 1rem">
                <!-- <v-btn color="primary" @click="validate()"> Siguiente </v-btn> -->
                <v-btn text @click="back(2)"> Atrás </v-btn>
              </v-row>

            </v-col>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div v-if="loading || loadingFinal" class="text-center">
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br><br>
            Estamos procesando tu solicitud, esto puede tardar un minuto. . .
          </div>

          <DetailForm v-else-if="!loading && !loadingFinal" :userData="userData" :petData="petData"
            @next-step="nextStep(3)" @back="back(3)" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import InitForm from "@/components/InitForm.vue";
import DetailForm from "@/components/DetailForm.vue";
import ShieldService from "../services/shield.service";
import Conditions from '../components/Conditions.vue';
//import Plan from '../components/Plan.vue';
import OpenPlan from '../components/OpenPlan.vue';
export default {
  name: "Form",
  components: {
    InitForm,
    OpenPlan,
    DetailForm,
    Conditions
  },
  data() {
    return {
      plan_1: [],
      plan_3: [],
      plan_6: [],
      plan_12: [],
      e1: 1,
      loading: false,
      loadingFinal: false,
      userData: {
        name: null,
        surname: null,
        serialNumber: null,
        rut: null,
        region: {
          name: null,
        },
        commune: {
          name: null,
        },
        address: null,
        email: null,
        phoneNumber: null,
        contractor: true,
        cantBenef: null,
        insured: {
          name: null,
          rut: null,
          email: null,
          phoneNumber: null,
        },
        BenefData: [
          {
            name: null,
            relation: null,
            porcent: null,
          },
          {
            name: null,
            relation: null,
            porcent: null,
          },
          {
            name: null,
            relation: null,
            porcent: null,
          },
          {
            name: null,
            relation: null,
            porcent: null,
          },
          {
            name: null,
            relation: null,
            porcent: null,
          },
        ]
      },
      planData: {},
      planForm: true,
      plans: [],
      success: null,
    };
  },
  mounted() {
    const query = this.$route.query;
    if (query) {
      if (query.success === "true") {
        this.success = true;
      } else if (query.success === "false") {
        this.success = false;
      }
    }
  },
  methods: {

    async nextStep(step, plan) {
      this.loading = true;
      if (step === 1) {
        // console.log("aca")
        await ShieldService.getPlans( //Espera el código ingresado en el step 1
          "1",
          this.userData.code
        ).then((response) => {
          this.plan_1 = response
          if (this.plan_1.length === 0) {
            this.$alert("No se encontraron planes, intente con un código distinto.", "Información", "info")
          } else {
            this.e1 = 2;
          }
        })
        await ShieldService.getPlans( //Espera el código ingresado en el step 1
          "3",
          this.userData.code
        ).then((response) => {
          this.plan_3 = response
          if (this.plan_3.length === 0) {
            this.$alert("No se encontraron planes, intente con un código distinto.", "Información", "info")
          } else {
            this.e1 = 2;
          }
        })
        await ShieldService.getPlans( //Espera el código ingresado en el step 1
          "6",
          this.userData.code
        ).then((response) => {
          this.plan_6 = response
          if (this.plan_6.length === 0) {
            this.$alert("No se encontraron planes, intente con un código distinto.", "Información", "info")
          } else {
            this.e1 = 2;
          }
        })
        await ShieldService.getPlans( //Espera el código ingresado en el step 1
          "12",
          this.userData.code
        ).then((response) => {
          this.plan_12 = response
          if (this.plan_12.length === 0) {
            this.$alert("No se encontraron planes, intente con un código distinto.", "Información", "info")
          } else {
            this.e1 = 2;
          }
        })
        // Redirigir a Flow
        // console.log("asdasda: ", this.plans)
        // console.log(this.plans.length)
        // if(this.plans.length===0){
        //   this.$alert("No se encontraron planes, intente con un código distinto.","Información","info")
        // }else{
        //    this.e1 = 2;
        // }

      } else if (step === 2) {
        this.planData = plan
        // console.log(this.planData)
        // var values;
        // try {
        //   values = {
        //     plan: plan
        //   }
        //   var carro = await ShieldService.createStep(values, 2, 0, this.userData.stepId)
        //   this.userData.stepId = carro.data.data._id
        // } catch (error) {
        //   console.log("Cart Fail");
        // }
        this.e1 = 3;
      } else if (step === 3) {
        // Conectar con SHIELD
        this.loadingFinal = true;
        try {
          const res = await ShieldService.createInsurance(
            this.userData,
            this.planData,
            // this.userData.stepId
          );
          // Redirigir a Flow
          window.location.href = res;
        } catch (error) {
          this.loadingFinal = false;
          this.loading = false;
          console.log(error)
          this.$alert("Ocurrió un error. Verifica que tus datos sean correctos", "Error", "error");
        }
      }
      this.loading = false;
    },
    back(step) {
      if (step === 2) {
        this.e1 = 1;
      } else if (step === 3) {
        this.e1 = 2;
      }
    },
  },
};
</script>
<style>
.plan-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
</style>