<template>
    <div style="width: 100%">
        <v-tabs v-model="selected" slider-size="6" slider-color="#ffb71b" dark fixed-tabs background-color="primary">
            <v-tab v-for="tab in tabs" :key="tab">
                <a class="nav-link" :class="{ active: tab ===select}" @click="setTab(tab)">
                    {{tab}}
                </a>
            </v-tab>
        </v-tabs>
        <slot></slot>
    </div>

</template>

<script>

export default {
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        selected: {
            type: String,
            required: true,
        }
    },
    methods: {
        setTab(tab){
            this.$emit('selected', tab);
        }
    }
}
</script>

<style scoped>
.nav-link{
    color:white;
}

</style>
