<template>

  <div class="items">
    <TabNav :tabs="['Plan Mensual', 'Plan 3 Meses', 'Plan 6 Meses', 'Plan 12 Meses']" :selected="selected"
      @selected="setSelected">
      <Tab :isSelected="selected === 'Plan Mensual'">
        <div class="plan" v-for="planes in plan_1" :key="planes">
          <h4 class="plan-name">{{planes.name}}</h4>
          <span class="priceCLP">{{ formatPrice(planes.priceCLP) }}</span>
          <span class="positionCLP">
            CLP /<br>
            MES
          </span>
          <span>UF {{planes.price}}</span>
          <v-col style="display: flex; flex-direction: column; align-items: center;">
            <h3>¿Qué cubre?</h3>
            <span>
              <div class="cover" v-for="pl in planes.coverage" :key="pl._id">
                <div class="desc">{{pl.description}}</div>
              </div>
            </span>
            <div style="height: 1rem;"></div>
            <v-btn color="primary" @click="validate(planes)"> ¡Lo quiero! </v-btn>
          </v-col>
        </div>
      </Tab>
      <Tab :isSelected="selected === 'Plan 3 Meses'">
        <div class="plan" v-for="planes in plan_3" :key="planes">
          <h4 class="plan-name">{{planes.name}}</h4>
          <span class="priceCLP">{{ formatPrice(planes.priceCLP) }}</span>
          <span class="positionCLP">
            CLP /<br>
            MES
          </span>
          <span>UF {{planes.price}}</span>
          <v-col style="display: flex; flex-direction: column; align-items: center;">
            <h3>¿Qué cubre?</h3>
            <span>
              <div class="cover" v-for="pl in planes.coverage" :key="pl._id">
                <div class="desc">{{pl.description}}</div>
              </div>
            </span>
            <div style="height: 1rem;"></div>
            <v-btn color="primary" @click="validate(planes)"> ¡Lo quiero! </v-btn>
          </v-col>
        </div>
      </Tab>
      <Tab :isSelected="selected === 'Plan 6 Meses'">
        <div class="plan" v-for="planes in plan_6" :key="planes">
          <h4 class="plan-name">{{planes.name}}</h4>
          <span class="priceCLP">{{ formatPrice(planes.priceCLP) }}</span>
          <span class="positionCLP">
            CLP /<br>
            MES
          </span>
          <span>UF {{planes.price}}</span>
          <v-col style="display: flex; flex-direction: column; align-items: center;">
            <h3>¿Qué cubre?</h3>
            <span>
              <div class="cover" v-for="pl in planes.coverage" :key="pl._id">
                <div class="desc">{{pl.description}}</div>
              </div>
            </span>
            <div style="height: 1rem;"></div>
            <v-btn color="primary" @click="validate(planes)"> ¡Lo quiero! </v-btn>
          </v-col>
        </div>
      </Tab>
      <Tab :isSelected="selected === 'Plan 12 Meses'">
        <div class="plan" v-for="planes in plan_12" :key="planes">
          <h4 class="plan-name">{{planes.name}}</h4>
          <span class="priceCLP">{{ formatPrice(planes.priceCLP) }}</span>
          <span class="positionCLP">
            CLP /<br>
            MES
          </span>
          <span>UF {{planes.price}}</span>
          <v-col style="display: flex; flex-direction: column; align-items: center;">
            <h3>¿Qué cubre?</h3>
            <span>
              <div class="cover" v-for="pl in planes.coverage" :key="pl._id">
                <div class="desc">{{pl.description}}</div>
              </div>
            </span>
            <div style="height: 1rem;"></div>
            <v-btn color="primary" @click="validate(planes)"> ¡Lo quiero! </v-btn>
          </v-col>
        </div>
      </Tab>
    </TabNav>
  </div>
</template>

<script>
//import Plan from '../components/Plan.vue';
import Form from "@/views/Form.vue";
import TabNav from "./TabNav.vue";
import Tab from "./Tab.vue";
import 'bootstrap/dist/css/bootstrap.min.css'

export default {
  views: {
    Form,
  },
  name: "Plan",
  props: ["plan_1", "plan_3", "plan_6", "plan_12"],
  components: {
    TabNav,
    Tab,
  },
  data: () => {
    return {
      selected: 'Plan Mensual',
      formatter: new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }),
    }
  },
  methods: {
    formatPrice(number) {
      return this.formatter.format(number);
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    setSelected(tab){
      this.selected = tab
    }
  }
}


</script>
<style lang="scss" scoped>


.items {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  align-content: center;
}
.plan {
  background-color: #ffffff;
  border: 2px solid #0070d1;
  width: 325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  height: fit-content;
  float: left;

  h4 {
    &.plan-name {
      text-transform: uppercase;
      color: #0070d1;
      letter-spacing: 0.3rem;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .tab {
    min-width: max-content;
  }

  .priceCLP {
    color: #00396b;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 3px;

  }

  .priceCLPdesc {
    color: #f52222;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 3px;
    text-decoration-line: line-through;
    margin: 0 auto;
  }

  .offer {
    color: #f00000e0;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 3px;

  }

  .positionCLPdesc {
    text-align: end;
    position: relative;
    left: 15px;
    top: -10px;
  }

  .positionCLP {
    text-align: end;
    position: relative;
    left: 115px;
    top: -60px;
  }

  //Cinta 20%DCTO.
  .ribbon {
    position: relative;
    left: 30x;
    top: 10px;
    width: 248px;
    text-align: center;
    font-size: 15px !important;
    background: #f52222;
    background: -webkit-gradient(linear, left top, left bottom, from(#f52222), to(#AB2C2C));
    background: -webkit-linear-gradient(top, #f52222, #AB2C2C);
    background: -moz-linear-gradient(top, #f52222, #AB2C2C);
    background: -ms-linear-gradient(top, #f52222, #AB2C2C);
    background: -o-linear-gradient(top, #f52222, #AB2C2C);
    background-image: -ms-linear-gradient(top, #f52222 0%, #AB2C2C 100%);
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 1px;
    -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 1px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 1px;
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
  }

  .ribbon h1 {
    text-align: center;
    font-size: 25px !important;
    color: #ffffff;
    text-shadow: #D65C5C 0 1px 0;
    margin: 0px;
    padding: 12px 10px;
  }

  .ribbon:before,
  .ribbon:after {
    content: '';
    position: absolute;
    display: block;
    bottom: -1em;
    border: 1.5em solid #C23A3A;
    z-index: -1;
  }

  .ribbon:before {
    left: -2em;
    border-right-width: 1.5em;
    border-left-color: transparent;
    -webkit-box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 1px;
    -moz-box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 1px;
    box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 1px;
  }

  .ribbon:after {
    right: -2em;
    border-left-width: 1.5em;
    border-right-color: transparent;
    -webkit-box-shadow: rgba(0, 0, 0, 0.4) -1px 1px 1px;
    -moz-box-shadow: rgba(0, 0, 0, 0.4) -1px 1px 1px;
    box-shadow: rgba(0, 0, 0, 0.4) -1px 1px 1px;
  }

  .ribbon .ribbon-content:before,
  .ribbon .ribbon-content:after {
    border-color: #871616 transparent transparent transparent;
    position: absolute;
    display: block;
    border-style: solid;
    bottom: -1em;
    content: '';
  }

  .ribbon .ribbon-content:before {
    left: 0;
    border-width: 1em 0 0 1em;
  }

  .ribbon .ribbon-content:after {
    right: 0;
    border-width: 1em 1em 0 0;
  }

  .ribbon-stitches-top {
    margin-top: 2px;
    border-top: 1px dashed rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
  }

  .ribbon-stitches-bottom {
    margin-bottom: 2px;
    border-top: 1px dashed rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.3);
  }

  //HASTA AQUI

  .cover {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ffb71b;
    align-items: center;
    justify-content: center;

    .desc {
      text-align: center;
    }

    .amount {
      color: #0070d1;
      font-weight: 600;
    }
  }

  .cover:last-child {
    border-bottom: none;
  }
}
</style>
