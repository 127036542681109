<template>
  <v-form ref="form" v-model="valid">
    <v-container style="justify-content: center; display: flex">
      <v-col style="max-width: 500px">
        
        <v-col>
          <v-text-field id="name"
            v-model="userData.name"
            :rules="nameRules"
            label="Nombre"
            required
            ><v-icon slot="prepend"> mdi-account </v-icon></v-text-field
          >
        </v-col>

        <v-col>
          <v-text-field id="email" 
            v-model="userData.email"
            :rules="emailRules"
            label="E-mail"
            required
            ><v-icon slot="prepend"> mdi-at </v-icon></v-text-field
          >
        </v-col>

        <v-col>
          <v-text-field id="phone" 
            v-model="userData.phoneNumber"
            :rules="phoneRules"
            :counter="9"
            prefix="+56"
            label="Telefono"
            required
            ><v-icon slot="prepend"> mdi-phone </v-icon></v-text-field
          >
        </v-col>
        
        <!-- <v-col>
          <v-checkbox
              v-model="userData.hasAcode" 
              >
              <template v-slot:label>
                 ¿Posee código de descuento?
              </template>
              </v-checkbox>
        </v-col> -->

        <v-col v-if="userData.hasAcode==true">
          <v-text-field id="code" 
            v-model="userData.code"
            label="Código"
            :rules="codeRules"
            ><v-icon slot="prepend"> mdi-tag </v-icon></v-text-field
          >
        </v-col>
        <v-btn color="primary" @click="validate()"> Siguiente </v-btn>
      </v-col>
    </v-container>
  </v-form>
</template>

<script>
import ShieldService from "../services/shield.service";

export default {
  name: "InitForm",
  props: ["userData"],
  mounted (){
    
    // window.addEventListener("beforeunload", this.change)
  },
  data: () => ({
    version: null,
    id: null,
    valid: false,
    nameRules: [(v) => !!v || "El nombre es requerido"],
    emailRules: [
      (v) => !!v || "El correo electrónico es equerido",
      (v) => /.+@.+\..+/.test(v) || "Ingrese un correo válido",
    ],
    codeRules: [
      (v) => (!!v && v.length <= 20) || "Ingrese un código válido",
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 9) || "Ingrese un número válido",
    ],
  }),
  methods: {
    //Validación del código ingresado
    validate() {
      if (this.$refs.form.validate()) {
        if(this.userData.hasAcode == false){
          this.userData.code = null;
        }
        // console.log(this.userData.code)
        this.$emit("next-step");
      }
    },
    async change() {

      // console.log("Estado de ventana:", window.closed)

      var values;
      if(this.version == null){
        values = [
          this.userData.name,
          this.userData.email,
          this.userData.phoneNumber,
        ]
        this.version = 1
      }else if (this.version != null){
        values = [
          this.userData.name,
          this.userData.email,
          this.userData.phoneNumber,
        ]
      }    

      try {

          var carro = await ShieldService.createStep(values, 1, this.version, this.userData.stepId)
          this.userData.stepId = carro.data.data._id
          this.version = 2
        } catch (error) {
          console.log("Cart Fail");
        }
    }
  },
};
</script>