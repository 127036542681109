<template>
  <v-form ref="form" v-model="valid">
    <v-container style="justify-content: center; display: flex; flex-direction: column">
      <div class="form-container">
        <v-col style="max-width: 450px">
          <h2 class="text-center">Tus datos</h2>

          <v-col>
            <v-text-field v-model="userData.name" :rules="nameRules" label="Nombre" required dense>
              <v-icon slot="prepend"> mdi-account </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field v-model="userData.surname" :rules="surnameRules" label="Apellido" required dense>
              <v-icon slot="prepend"> mdi-account-check </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field v-model.lazy="newRut" :rules="rutRules" label="RUT" required dense hide-details="auto"
              @input="changeRut">
              <v-icon slot="prepend"> mdi-key </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field v-model="userData.serialNumber" :rules="serialNumberRules" label="N de serie" required dense>
              <v-icon slot="prepend"> mdi-numeric </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-select :items="regions" v-model="userData.region" :rules="regionRules" :loading="loadingRegions"
              v-on:change="getCommunes()" item-text="name" label="Region" required dense return-object>
              <v-icon slot="prepend"> mdi-map-marker </v-icon>
            </v-select>
          </v-col>

          <v-col>
            <v-select :items="communes" v-model="userData.commune" :rules="communeRules" :loading="loadingCommunes"
              :disabled="loadingCommunes || userData.region === null" item-text="name" label="Comuna" required dense
              return-object>
              <v-icon slot="prepend"> mdi-select-place </v-icon>
            </v-select>
          </v-col>

          <v-col>
            <v-text-field v-model="userData.address" :rules="addressRules" label="Dirección" required dense>
              <v-icon slot="prepend">
                mdi-map-marker-outline
              </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field v-model="userData.email" :rules="emailRules" label="E-mail" required dense>
              <v-icon slot="prepend"> mdi-at </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field v-model="userData.phoneNumber" :rules="phoneRules" :counter="9" prefix="+56" label="Telefono"
              required dense>
              <v-icon slot="prepend"> mdi-phone </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-checkbox :rules="conditionsRules" required dense>
              <v-icon slot="prepend"> mdi-file-document </v-icon>
              <template v-slot:label>
                Acepto Términos y Condiciones&thinsp;
              </template>
            </v-checkbox>
          </v-col>

          <v-col>
            <v-checkbox :rules="exclusionRules" required dense>
              <v-icon slot="prepend"> mdi-file-document-multiple</v-icon>
              <template v-slot:label>
                Acepto las coberturas y exclusiones que se me han informado&thinsp;
              </template>
            </v-checkbox>
          </v-col>

          <v-col>
            <v-checkbox v-model="userData.contractor" dense>
              <v-icon slot="prepend"> mdi-comment-question-outline </v-icon>
              <template v-slot:label>
                El contratante es el asegurado&thinsp;
              </template>
            </v-checkbox>
          </v-col>

        </v-col>

        <v-col style="max-width: 450px">

          <h2 v-if="userData.contractor == false" class="text-center">Datos Asegurado</h2>

          <v-col>
            <v-text-field v-if="userData.contractor == false" v-model.lazy="newRut2" :rules="rutAseguradoRules"
              label="RUT" required dense hide-details="auto" @input="changeRut2">
              <v-icon slot="prepend"> mdi-key </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field v-if="userData.contractor == false" v-model="userData.insured.name"
              :rules="nameAseguradoRules" label="Nombre" required dense>
              <v-icon slot="prepend"> mdi-account </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field v-if="userData.contractor == false" v-model="userData.insured.email"
              :rules="emailAseguradoRules" label="E-mail" required dense>
              <v-icon slot="prepend"> mdi-at </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field v-if="userData.contractor == false" v-model="userData.insured.phoneNumber"
              :rules="phoneAseguradoRules" :counter="9" prefix="+56" label="Telefono" required dense>
              <v-icon slot="prepend"> mdi-phone </v-icon>
            </v-text-field>
          </v-col>

          <h2 class="text-center">Beneficiarios</h2>

          <v-col>
            <v-select v-model="userData.cantBenef" label="Cantidad de Beneficiarios" :rules="cantBenefRules" required
              :items="items">
              <v-icon slot="prepend"> mdi-account-multiple-plus </v-icon>
            </v-select>
          </v-col>

          <div v-if="userData.cantBenef == 1">
            <v-col>

              <h2 class="text-center">Beneficiario 1</h2>

              <v-text-field v-model="userData.BenefData[0].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[0].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[0].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

          </div>

          <div v-if="userData.cantBenef == 2">
            <v-col>

              <h2 class="text-center">Beneficiario 1</h2>

              <v-text-field v-model="userData.BenefData[0].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[0].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[0].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

            <v-col>

              <h2 class="text-center">Beneficiario 2</h2>

              <v-text-field v-model="userData.BenefData[1].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[1].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[1].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

          </div>

          <div v-if="userData.cantBenef == 3">
            <v-col>

              <h2 class="text-center">Beneficiario 1</h2>

              <v-text-field v-model="userData.BenefData[0].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[0].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[0].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

            <v-col>

              <h2 class="text-center">Beneficiario 2</h2>

              <v-text-field v-model="userData.BenefData[1].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[1].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[1].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

            <v-col>

              <h2 class="text-center">Beneficiario 3</h2>

              <v-text-field v-model="userData.BenefData[2].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[2].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[2].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

          </div>

          <div v-if="userData.cantBenef == 4">
            <v-col>

              <h2 class="text-center">Beneficiario 1</h2>

              <v-text-field v-model="userData.BenefData[0].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[0].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[0].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

            <v-col>

              <h2 class="text-center">Beneficiario 2</h2>

              <v-text-field v-model="userData.BenefData[1].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[1].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[1].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

            <v-col>

              <h2 class="text-center">Beneficiario 3</h2>

              <v-text-field v-model="userData.BenefData[2].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[2].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[2].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

            <v-col>

              <h2 class="text-center">Beneficiario 4</h2>

              <v-text-field v-model="userData.BenefData[3].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[3].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[3].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

          </div>

          <div v-if="userData.cantBenef == 5">
            <v-col>

              <h2 class="text-center">Beneficiario 1</h2>

              <v-text-field v-model="userData.BenefData[0].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[0].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[0].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

            <v-col>

              <h2 class="text-center">Beneficiario 2</h2>

              <v-text-field v-model="userData.BenefData[1].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[1].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[1].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

            <v-col>

              <h2 class="text-center">Beneficiario 3</h2>

              <v-text-field v-model="userData.BenefData[2].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[2].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[2].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

            <v-col>

              <h2 class="text-center">Beneficiario 4</h2>

              <v-text-field v-model="userData.BenefData[3].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[3].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[3].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

            <v-col>

              <h2 class="text-center">Beneficiario 5</h2>

              <v-text-field v-model="userData.BenefData[4].name" :rules="benefNameRules" label="Nombre" required dense
                @change="herencia()">
                <v-icon slot="prepend"> mdi-account </v-icon>
              </v-text-field>

              <v-text-field v-model="userData.BenefData[4].porcent" :rules="benefPorcentRules" label="Porcentaje"
                @change="herencia()" required dense>
                <v-icon slot="prepend"> % </v-icon>
              </v-text-field>

              <v-select v-model="userData.BenefData[4].relation" label="Parentesco" required :rules="benefParentRules"
                @change="herencia()" :items="itemsRelation">
                <v-icon slot="prepend"> mdi-account-alert </v-icon>
              </v-select>

            </v-col>

          </div>

        </v-col>
      </div>
      <v-row style="justify-content: center; margin-top: 1rem">
        <v-btn color="primary" max-width="200px" @click="validate()"> Confirmar y pagar </v-btn>
        <v-btn text max-width="200px" @click="back()"> Atrás </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import RutUtils from "../utils/rut.utils";
import CountryService from "../services/country.service";
import ShieldService from "../services/shield.service";
import swal from 'sweetalert';

export default {
  name: "DetailForm",
  props: ["userData", "petData"],
  data: () => ({
    rule: 0,
    itemsRelation: ['Hijo(a)', 'Conyuge','Pareja','Otro'],
    items: ['1','2','3','4','5'],
    valid: false,
    nameRules: [(v) => !!v || "El nombre es requerido"],
    surnameRules: [(v) => !!v || "El apellido es requerido"],
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no valido",
    ],
    serialNumberRules: [
      (v) => !!v || "El numero de serie es requerido",
      (v) => (!!v && v.length > 8) || "Ingrese número de serie correctamente",
    ],
    regionRules: [
      (v) => !!v || "Ingresa la region en la que vives",
    ],
    communeRules: [(v) => !!v || "Necesitamos saber en que comuna vives"],
    addressRules: [(v) => !!v || "Debe ingresar su dirección"],
    emailRules: [
      (v) => !!v || "El correo electrónico es equerido",
      (v) => /.+@.+/.test(v) || "Ingrese un correo válido",
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 9) || "Ingrese un número válido",
    ],
    conditionsRules: [
      (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
    ],
    exclusionRules: [
      (v) => !!v || "Debe aceptar las coberturas y exclusiones",
    ],
    nameAseguradoRules: [(v) => !!v || "El nombre es requerido"],
    rutAseguradoRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no valido",
    ],
    emailAseguradoRules: [
      (v) => !!v || "El correo electrónico es equerido",
      (v) => /.+@.+/.test(v) || "Ingrese un correo válido",
    ],
    phoneAseguradoRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 9) || "Ingrese un número válido",
    ],
    cantBenefRules: [
      (v) => !!v || "Es necesario ingresar un(os) beneficiario(os)",
    ],
    benefNameRules: [
      (v) => !!v || "El nombre es requerido",
    ],
    benefPorcentRules: [
      (v) => !!v || "El porcentaje es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v <= 100) || "El porcentaje tope es 100%",
    ],
    benefParentRules: [
      (v) => !!v || "Debe seleccionar el parentezco",
    ],
    newRut: "", 
    newRut2: "",
    regions: [],
    communes: [],
    loadingRegions: false,
    loadingCommunes: false,
  }),
  mounted() {
    this.getRegions();
  },
  methods: {
    back() {
      this.$emit("back");
    },
    validate() {
      var porcent = 0
      this.userData.BenefData.forEach(benef => {
        if(benef.porcent != null){
          porcent = porcent + Number(benef.porcent)
        }
      });
      if(porcent <= 100){
        if (this.$refs.form.validate()) {
          this.$emit("next-step");
        }
      } else {
        swal({
          title: "Atención!",
          text: "La suma de los porcentajes debe sumar 100%. Verifique para continuar!",
          icon: "warning",
        })
      }
      
    },
    herencia(){
      this.rule = 0
      this.userData.BenefData.forEach(benef => {
        if(benef.porcent != null){
          this.rule = this.rule + Number(benef.porcent)
        }
      });
    },
    changeRut(rut) {
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
    }, 
    changeRut2(rut) {
      this.userData.insured.rut = RutUtils.cleanRut(rut);
      this.newRut2 = RutUtils.formatRut(rut);
    },
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.region) {
        this.loadingCommunes = true;
        this.userData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    async change(){

      var values;

      try {         
        values = [
        this.userData.name,
        this.userData.surname,
        this.userData.serialNumber,
        this.userData.rut,
        this.userData.region.name,
        this.userData.commune.name,
        this.userData.address,
        this.userData.email,
        this.userData.phoneNumber,
        ]
        var carro = await ShieldService.createStep(values, 3, 0, this.userData.stepId)
        this.userData.stepId = carro.data.data._id
      } catch (error) {
        console.log("Cart Fail");
      }
    }
  }
};

</script>
<style lang="scss">
.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.species-container {
  display: block;
  position: relative;
  text-align: center;
  // margin-bottom: 2rem;
  cursor: pointer;

  .species-selector {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    // padding: 0.5rem 1rem;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    // border: 1px solid #f5f5f5;
    cursor: pointer;

    .radio-input {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      background-color: #f5f5f5;
      box-shadow: 4px 4px 4px #c6c6c6;
    }
    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
    }

    .species-icon {
      height: 100px;
      cursor: pointer;
    }
  }
}

.v-col {
  text-align: left;
}

.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}

@media (max-width: 800px) {
  .form-container {
    flex-direction: column;
    align-items: center;
  }
}
</style>